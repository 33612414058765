import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js'
import React, {useContext, useState} from 'react'
import {Redirect, navigate} from '@reach/router' // highlight-line

import CheckoutLayout from '../../components/gallery/checkout/CheckoutLayout'
import FormArrow from '../../images/svgs/formarrow.svg'
import updateAction from '../../components/gallery/checkout/updateAction'
import {useForm} from 'react-hook-form'
import {useStateMachine} from 'little-state-machine'

const Details = (props) => {
  // react-hook-form
  const {state, action} = useStateMachine(updateAction)
  const {register, handleSubmit, watch, errors} = useForm({
    defaultValues: state.data,
  })
  const onSubmit = (data) => {
    action(data)
    navigate('/checkout/address')
  }
  return (
    <CheckoutLayout>
      <div className="flex h-full  flex-wrap  ">
        <form className="w-full md:max-w-lg" onSubmit={handleSubmit(onSubmit)}>
          {/* <div className="uppercase tracking-wide  text-sm mb-4">Contact Information</div> */}
          <div className="flex flex-col my-2 md:flex-row flex-wrap">
            <div className="w-full md:w-1/2 md:mb-0">
              <label className="block  tracking-wide  text-xs mb-2" for="firstName">
                First Name*
              </label>
              <input
                ref={register({required: true})}
                className="outline-none  w-full   rounded py-3 px-4 mb-3 leading-tight focus:bg-black focus:text-white"
                id="firstName"
                name="firstName"
                type="text"
                required
                placeholder="John"></input>
            </div>
            <div className="w-full md:w-1/2 md:px-3">
              <label className="block  tracking-wide  text-xs mb-2 md:ml-2" for="lastName">
                Last Name*
              </label>
              <input
                ref={register({required: true})}
                className="outline-none  w-full bg-gray-200  rounded py-3 md:ml-2 px-4 leading-tight focus:bg-black focus:text-white"
                id="lastName"
                name="lastName"
                type="text"
                required
                placeholder="Dore"></input>
            </div>
            <div className="w-full md:w-1/2 ">
              <label className="block tracking-wide  text-xs md:my-0 md:mb-2 my-2" for="email">
                Email Address*
              </label>
              <input
                ref={register({required: true})}
                className="outline-none  w-full  rounded py-3 px-4 mb-3 leading-tight focus:bg-black focus:text-white"
                id="email"
                name="email"
                type="text"
                required
                placeholder="Email Address"></input>
            </div>
          </div>
          <button className="text-white flex w-auto bg-gray p-2 rounded ">
            <FormArrow className="w-3 self-center" />
          </button>
        </form>
      </div>
    </CheckoutLayout>
  )
}

export default Details
